
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiSalesInteractions, ApiBase, ApiAccount } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import moment from "moment";
import {
  commonChangeDefaultDate,
  formatDate,
  setModuleBCN,
} from "@/core/directive/function/common";

export default defineComponent({
  name: "influencer-groups-information",
  components: {},
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: route.params.id,
      target_name: "",
      target_owner: "",
      target_type: "",
      account_id: "",
      account_groups_id: "",
      mb_segmentation: [],
      start: "",
      close_date: "",
      stage: "",
      probability: 0,
      target_amount: "",
      completion: "",
      requried_resources: [],
      created_at: null,
      updated_at: null,
      created_uid: "",
      updated_uid: "",
      __show: {
        created_user: "",
        updated_user: "",
      },
    });

    const rules = ref({
      target_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      target_owner: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      target_type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      close_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      start: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      account_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      account_groups_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "sales_interactions_target_type",
          "sales_interactions_requried_resources",
          "interactive_activity_stage",
        ],
      });
      if (data.code == 0) {
        typeOptions.value = data.data.sales_interactions_target_type.items;
        requriedResourcesOptions.value =
          data.data.sales_interactions_requried_resources.items;
        stageOptions.value = data.data.interactive_activity_stage.items;
      }
    };

    const ownerOptions = ref([]);
    const ownerLoading = ref<boolean>(false);
    const typeOptions = ref([]);
    const stageOptions = ref([]);
    const influencerOptions = ref([]);
    const influencerLoading = ref<boolean>(false);
    const influencerGroupOptions = ref([]);
    const influencerGroupLoading = ref<boolean>(false);
    const requriedResourcesOptions = ref([]);
    const campaignsTableData = ref([]);
    const stepTableData = ref([]);

    const switchUserType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status ? (ownerLoading.value = true) : (ownerLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchUserValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          ownerOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getUserSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchUserType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };

      const { data } = await ApiBase.getUserSourceData(params);
      switchUserType(type, false);
      if (data.code == 0) {
        switchUserValue(type, data.data);
      }
    };

    const debounceUserSearch = _.debounce(getUserSourceData, 1000);
    const searchOwnerItems = (query) => {
      debounceUserSearch(0, query);
    };

    const getAccountSourceData = async (value?: string, id?: string) => {
      influencerLoading.value = true;
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };
      const { data } = await ApiBase.getAccountSourceData(params);
      influencerLoading.value = false;
      if (data.code == 0) {
        influencerOptions.value = data.data;
      }
    };
    const debounceAccountSearch = _.debounce(getAccountSourceData, 1000);
    const searchInfluencerItems = (query) => {
      debounceAccountSearch(query);
    };

    const getInfluencerGroupSourceData = async () => {
      const { data } = await ApiBase.getInfluencerGroupSourceData({
        max_item: "all",
      });
      influencerGroupLoading.value = false;
      if (data.code == 0) {
        influencerGroupOptions.value = data.data;
      }
    };

    const getSalesInteractionsInfo = () => {
      loading.value = true;
      ApiSalesInteractions.getSalesInteractionsInfo({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            formData.value = data.data;
            if (data.data.target_owner != "") {
              getUserSourceData(0, "", data.data.target_owner);
            }
            if (data.data.account_id != "") {
              getAccountSourceData("", data.data.account_id);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }

          ApiSalesInteractions.updateSalesInteractions(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deleteSalesInteractions = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Interactive Activities?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiSalesInteractions.deleteSalesInteractions({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Interactive Activities has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "interactive-activities" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const targetTypeCheng = (e) => {
      formData.value.account_id = "";
      influencerOptions.value = [];
      formData.value.account_groups_id = "";
    };

    const getStatusLabel = (status) => {
      let str = "",
        statusClass = "";
      switch (status) {
        case 10:
          str = t("marketingCampaigns.plan");
          statusClass = "badge-light-info";
          break;
        case 20:
          str = t("marketingCampaigns.processing");
          statusClass = "badge-light-warning";
          break;
        case 30:
          str = t("marketingCampaigns.completed");
          statusClass = "badge-light-success";
          break;
        case 40:
          str = t("marketingCampaigns.void");
          statusClass = "badge-light-danger";
          break;
        default:
          break;
      }
      return { str, statusClass };
    };

    onBeforeMount(() => {
      getSalesInteractionsInfo();
      getDropdownOptions();
      getInfluencerGroupSourceData();
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
    });

    return {
      t,
      formatDate,
      commonChangeDefaultDate,
      loading,
      formData,
      rules,
      formRef,
      submitButton,
      deleteButton,
      ownerOptions,
      typeOptions,
      stageOptions,
      influencerOptions,
      influencerLoading,
      influencerGroupOptions,
      influencerGroupLoading,
      requriedResourcesOptions,
      campaignsTableData,
      stepTableData,
      searchOwnerItems,
      searchInfluencerItems,
      getSalesInteractionsInfo,
      submit,
      deleteSalesInteractions,
      targetTypeCheng,
      getStatusLabel,
    };
  },
});
